import React, { useRef } from 'react';
import { UIHeadlineProps, UITagLevel } from './types';
import { Column, Container, Row } from '@/components/base/gridview';
import { motion, useInView } from 'framer-motion';

const animations = {
  container: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
        delayChildren: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  title: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.75,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Headline: React.FC<UIHeadlineProps> = ({
  title,
  subTitle,
  description,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  const renderDynamicTag = (
    tag: UITagLevel | undefined,
    className: string,
    text: any
  ) => {
    if (tag && text) {
      const MotionTag = motion[tag] || tag;
      return (
        <MotionTag className={className} variants={animations.title}>
          {text}
        </MotionTag>
      );
    }

    return null;
  };

  return (
    <motion.div
      className="headline"
      variants={animations.container}
      initial="hidden"
      animate={`${isInView ? 'show' : 'hidden'}`}
      viewport={{ once: true, amount: 0.25 }}
      ref={ref}
    >
      <Container>
        <Row>
          <Column>
            {subTitle &&
              renderDynamicTag(
                subTitle.tag,
                `headline-sub-title ${subTitle.centered ? 'center' : ''}`,
                subTitle.text
              )}
            <>
              {title &&
                renderDynamicTag(
                  title.tag,
                  `headline-title ${title.centered ? 'center' : ''}`,
                  title.text
                )}
              {description && (
                <p className="headline-description">{description}</p>
              )}
            </>
          </Column>
        </Row>
      </Container>
    </motion.div>
  );
};

export default Headline;
